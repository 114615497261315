(function ($) {
  "use strict";
  $(function () {
    $(document).ready(function () {
      $(".prod")
        .mouseenter(function () {
          var currHeight = $(this).height();
          $(this).height(currHeight);
          $(this).addClass("js-hover");
        })
        .mouseleave(function () {
          $(this).removeClass("js-hover");
          $(this).attr("style", "");
        });

      $(".amplifier__slide").each(function () {
        var $block = $(this).find(".prod");
        $block.addClass("js-hover");
        var heightOfBlock = $block.find(".prod__main").height() + 20;
        $(this).closest(".slick-slide").height(heightOfBlock);
        $block.removeClass("js-hover");
      });

      $(document).on("click", ".x-compare", function () {
        var $this = $(this);
        var $product = $this.closest(".prod");
        if (!$(this).hasClass("active")) {
          $.get(
            "/local/ajax/list_compare.php",
            {
              action: "ADD_TO_COMPARE_LIST",
              id: $this.data("id"),
            },
            function (data) {
              $this.addClass("active");
              $this.attr("title", "Удалить из сравнения");
              $(".notification-box")
                .find(".notification-box__image")
                .attr("src", $product.find(".prod__wrap img").attr("src"));
              $(".notification-box")
                .find(".notification-box__title")
                .text($product.find(".prod__title").text() + " добавлен к сравнению");
              $(".notification-box").find(".notification-box__button").text("К сравнению");
              $(".notification-box").find(".notification-box__button").attr("href", "/catalog/compare/");
              $(".notification-box").fadeIn(500).delay(3000).fadeOut(500);
            }
          );
        } else {
          $.get(
            "/local/ajax/list_compare.php",
            {
              action: "DELETE_FROM_COMPARE_LIST",
              id: $this.data("id"),
            },
            function (data) {
              $this.removeClass("active");
              $this.attr("title", "Добавить к сравнению");
              $(".notification-box")
                .find(".notification-box__image")
                .attr("src", $product.find(".prod__wrap img").attr("src"));
              $(".notification-box")
                .find(".notification-box__title")
                .text($product.find(".prod__title").text() + " удален из сравнения");
              $(".notification-box").find(".notification-box__button").text("К сравнению");
              $(".notification-box").find(".notification-box__button").attr("href", "/catalog/compare/");
              $(".notification-box").fadeIn(500).delay(3000).fadeOut(500);
            }
          );
        }
        $("#info").load("/local/ajax/header_counters.php");
      });

      $(document).on("click", ".x-favorite", function () {
        var $this = $(this);
        var $product = $this.closest(".prod");
        if (!$(this).hasClass("active")) {
          $.get(
            "/local/ajax/favorites.php",
            {
              action: "add",
              id: $this.data("id"),
            },
            function (data) {
              $this.addClass("active");
              $this.attr("title", "Удалить из избранного");
              $(".notification-box")
                .find(".notification-box__image")
                .attr("src", $product.find(".prod__wrap img").attr("src"));
              $(".notification-box")
                .find(".notification-box__title")
                .text($product.find(".prod__title").text() + " добавлен в избранное");
              $(".notification-box").find(".notification-box__button").text("В избранное");
              $(".notification-box").find(".notification-box__button").attr("href", "/personal/favorites/");
              $(".notification-box").fadeIn(500).delay(3000).fadeOut(500);
            }
          );
        } else {
          $.get(
            "/local/ajax/favorites.php",
            {
              action: "delete",
              id: $this.data("id"),
            },
            function (data) {
              $this.removeClass("active");
              $this.attr("title", "Добавить в избранное");
              $(".notification-box")
                .find(".notification-box__image")
                .attr("src", $product.find(".prod__wrap img").attr("src"));
              $(".notification-box")
                .find(".notification-box__title")
                .text($product.find(".prod__title").text() + " удален из избранного");
              $(".notification-box").find(".notification-box__button").text("В избранное");
              $(".notification-box").find(".notification-box__button").attr("href", "/personal/favorites/");
              $(".notification-box").fadeIn(500).delay(3000).fadeOut(500);
            }
          );
        }
        $("#info").load("/local/ajax/header_counters.php");
      });

      $(document).on("click", ".x-prod-close", function () {
        var $this = $(this);
        var $product = $this.closest(".prod");
        $.get(
          "/local/ajax/favorites.php",
          {
            action: "delete",
            id: $this.data("id"),
          },
          function (data) {
            $this.closest(".cell").remove();
            $(".notification-box")
              .find(".notification-box__image")
              .attr("src", $product.find(".prod__wrap img").attr("src"));
            $(".notification-box")
              .find(".notification-box__title")
              .text($product.find(".prod__title").text() + " удален из избранного");
            $(".notification-box").find(".notification-box__button").text("В избранное");
            $(".notification-box").find(".notification-box__button").attr("href", "/personal/favorites/");
            $(".notification-box").fadeIn(500).delay(3000).fadeOut(500);
          }
        );
        $("#info").load("/local/ajax/header_counters.php");
      });
    });
  });
})(jQuery);
