(function ($) {
  "use strict";
  $(function () {
    $(document).click(function (e) {
      var $currItem = $(e.target).closest(".header__zoom");
      if ($currItem.length !== 0) {
        $currItem.closest(".header__search").addClass("js-active");
      }
    });
    $(document).click(function (e) {
      var $currItem = $(e.target).closest(".header__reset");
      if ($currItem.length !== 0) {
        $currItem.closest(".header__search").removeClass("js-active");
      }
    });
  });
})(jQuery);
