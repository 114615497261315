import "@fancyapps/fancybox";
import "@fortawesome/fontawesome-free/js/all.js";
import "./foundation.js";
import "./carousels.js";
import "./handleInputNumber.js";
import "./handleFiles.js";
import "./handleSearch.js";
import "./handleMenu.js";
import "./handleProd.js";
import "./handleToTop.js";
import "./products.js";
import "./fileUpload.js";
import "./vacancies.js";
import "./handleHeaderDropMenu.js";

import "../scss/style.scss";
(function($) {
    "use strict";
    $(function() {
        $(document).ready(function() {
            $('.x-solution-toggler').click(function() {
                $('.x-solution:not(.hide)').addClass('hide');
            });
            $('.x-solution-toggler-two').click(function() {
                $('.x-solution-two:not(.hide)').addClass('hide');
            });
            $('.x-solution-toggler-three').click(function() {
                $('.x-solution-three:not(.hide)').addClass('hide');
            });

            $('.x-rubric-select-all').click(function() {
                $('.x-rubric').prop("checked", true);
            });
        });
    });
})(jQuery);