(function ($) {
  "use strict";
  $(function () {
    // Передаем название вакансии в форму "Ответить на вакансию"
    var jobsCount = $(".js-jobs-item").length;
    for (var i = 1; i <= jobsCount; i++) {
      $(".js-jobs-button-" + i).click(function () {
        var jobsName = $(this).closest(".js-jobs-item").find(".js-jobs-name").html();
        $(".js-jobs-input").val(jobsName);
      });
    }
  });
})(jQuery);
