(function ($) {
  "use strict";
  $(function () {
    $(document).click(function (e) {
      var $currItem = $(e.target).closest(".header__elem_fourth.js-active");
      if ($currItem.length !== 0) {
        $currItem.removeClass("js-active");
        $currItem.closest(".header__cont").addClass("js-active");
      }
    });

    $(document).click(function (e) {
      var $currItem = $(e.target).closest(".header__drop_other button");
      if ($currItem.length !== 0) {
        $currItem.closest(".header__cont").removeClass("js-active");
        $(".header__fav_spec").text("Выход");
      }
    });
  });
})(jQuery);
