(function ($) {
  "use strict";
  $(function () {
    $(document).click(function (e) {
      var $currItem = $(e.target).closest(".header__menu");
      if ($currItem.length !== 0) {
        $currItem.toggleClass("js-active");
        $("#menu,#top-line,#info").toggleClass("show-for-large");
      }
    });
  });
})(jQuery);
