(function ($) {
  "use strict";
  $(function () {
    function importAll(r) {
      const files = {};
      r.keys().map((item) => {
        files[item.replace("./", "")] = r(item);
      });
      return files;
    }
    importAll(require.context("../", true, /\.(jpe?g|png|gif|svg|ico|webmanifest|json|woff|woff2|eot|ttf|otf|mp4)$/i));
  });
})(jQuery);
