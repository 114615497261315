import "slick-carousel";
(function ($) {
  "use strict";
  $(function () {
    $(".carousel-main").slick({
      infinite: true,
      dots: false,
      arrows: true,
      slidesToShow: 1,
      slidesToScroll: 1,
      autoplay: true,
      autoplaySpeed: 5000,
      autoplayHoverPause: true,
      fade: true,
      swipeToSlide: true,
      prevArrow:
        '<span><svg class="svg-inline--fa fa-angle-left fa-w-8 slick-prev fa-4x" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 512"><path fill="currentColor" d="M31.7 239l136-136c9.4-9.4 24.6-9.4 33.9 0l22.6 22.6c9.4 9.4 9.4 24.6 0 33.9L127.9 256l96.4 96.4c9.4 9.4 9.4 24.6 0 33.9L201.7 409c-9.4 9.4-24.6 9.4-33.9 0l-136-136c-9.5-9.4-9.5-24.6-.1-34z"></path></svg></span>',
      nextArrow:
        '<span><svg class="svg-inline--fa fa-angle-right fa-w-8 slick-next fa-4x" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 512"><path fill="currentColor" d="M224.3 273l-136 136c-9.4 9.4-24.6 9.4-33.9 0l-22.6-22.6c-9.4-9.4-9.4-24.6 0-33.9l96.4-96.4-96.4-96.4c-9.4-9.4-9.4-24.6 0-33.9L54.3 103c9.4-9.4 24.6-9.4 33.9 0l136 136c9.5 9.4 9.5 24.6.1 34z"></path></svg></span>',
    });
    $(".voice").slick({
      infinite: true,
      dots: false,
      arrows: true,
      slidesToScroll: 1,
      autoplay: true,
      autoplaySpeed: 5000,
      autoplayHoverPause: true,
      fade: false,
      variableWidth: true,
      swipeToSlide: true,
      prevArrow:
        '<span class="slick-prev"><svg width="6" height="10" viewBox="0 0 6 10" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M5 9L1 5L5 1" stroke="#C4C4C4" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/></svg></span>',
      nextArrow:
        '<span class="slick-next"><svg width="6" height="10" viewBox="0 0 6 10" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M5 9L1 5L5 1" stroke="#C4C4C4" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/></svg></span>',
    });
    $(".amplifier").slick({
      infinite: true,
      dots: false,
      arrows: true,
      slidesToScroll: 1,
      slidesToShow: 5,
      autoplay: true,
      autoplaySpeed: 5000,
      autoplayHoverPause: true,
      fade: false,
      swipeToSlide: true,
      prevArrow:
        '<span class="slick-prev"><svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><g opacity="0.5"><path fill-rule="evenodd" clip-rule="evenodd" d="M12.7071 5.70711C13.0976 5.31658 13.0976 4.68342 12.7071 4.29289C12.3166 3.90237 11.6834 3.90237 11.2929 4.29289L4.29289 11.2929C3.90237 11.6834 3.90237 12.3166 4.29289 12.7071L11.2929 19.7071C11.6834 20.0976 12.3166 20.0976 12.7071 19.7071C13.0976 19.3166 13.0976 18.6834 12.7071 18.2929L7.41421 13H19C19.5523 13 20 12.5523 20 12C20 11.4477 19.5523 11 19 11H7.41421L12.7071 5.70711Z" fill="#414141"/></g></svg></span>',
      nextArrow:
        '<span class="slick-next"><svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><g opacity="0.5"><path fill-rule="evenodd" clip-rule="evenodd" d="M12.7071 5.70711C13.0976 5.31658 13.0976 4.68342 12.7071 4.29289C12.3166 3.90237 11.6834 3.90237 11.2929 4.29289L4.29289 11.2929C3.90237 11.6834 3.90237 12.3166 4.29289 12.7071L11.2929 19.7071C11.6834 20.0976 12.3166 20.0976 12.7071 19.7071C13.0976 19.3166 13.0976 18.6834 12.7071 18.2929L7.41421 13H19C19.5523 13 20 12.5523 20 12C20 11.4477 19.5523 11 19 11H7.41421L12.7071 5.70711Z" fill="#414141"/></g></svg></span>',
      responsive: [
        { breakpoint: 1100, settings: { slidesToShow: 4 } },
        { breakpoint: 960, settings: { slidesToShow: 3 } },
        { breakpoint: 640, settings: { slidesToShow: 2 } },
        { breakpoint: 500, settings: { slidesToShow: 1 } },
      ],
    });

    var $wing = $(".wing");
    $wing.slick({
      infinite: true,
      dots: false,
      arrows: true,
      slidesToShow: 1,
      slidesToScroll: 1,
      autoplay: true,
      autoplaySpeed: 5000,
      autoplayHoverPause: true,
      prevArrow:
        '<span class="slick-prev"><svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg"><rect x="40" y="40" width="40" height="40" rx="20" transform="rotate(-180 40 40)" fill="#FAF9FB"/><path fill-rule="evenodd" clip-rule="evenodd" d="M19.4343 26.1657C19.7467 26.4781 20.2533 26.4781 20.5657 26.1657C20.8781 25.8533 20.8781 25.3468 20.5657 25.0343L16.3314 20.8H25.6C26.0418 20.8 26.4 20.4419 26.4 20C26.4 19.5582 26.0418 19.2 25.6 19.2H16.3314L20.5657 14.9657C20.8781 14.6533 20.8781 14.1468 20.5657 13.8343C20.2533 13.5219 19.7467 13.5219 19.4343 13.8343L13.8343 19.4343C13.5219 19.7468 13.5219 20.2533 13.8343 20.5657L19.4343 26.1657Z" fill="#C4C4C4"/></svg></span>',
      nextArrow:
        '<span class="slick-next"><svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg"><rect x="40" y="40" width="40" height="40" rx="20" transform="rotate(-180 40 40)" fill="#FAF9FB"/><path fill-rule="evenodd" clip-rule="evenodd" d="M19.4343 26.1657C19.7467 26.4781 20.2533 26.4781 20.5657 26.1657C20.8781 25.8533 20.8781 25.3468 20.5657 25.0343L16.3314 20.8H25.6C26.0418 20.8 26.4 20.4419 26.4 20C26.4 19.5582 26.0418 19.2 25.6 19.2H16.3314L20.5657 14.9657C20.8781 14.6533 20.8781 14.1468 20.5657 13.8343C20.2533 13.5219 19.7467 13.5219 19.4343 13.8343L13.8343 19.4343C13.5219 19.7468 13.5219 20.2533 13.8343 20.5657L19.4343 26.1657Z" fill="#C4C4C4"/></svg></span>',
    });
    var $fort = $(".fort");
    $fort.slick({
      infinite: true,
      dots: false,
      arrows: true,
      slidesToShow: 5,
      slidesToScroll: 1,
      autoplay: false,
      autoplaySpeed: 5000,
      autoplayHoverPause: true,
      prevArrow:
        '<span class="slick-prev"><svg width="8" height="14" viewBox="0 0 8 14" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M7 13L1 7L7 1" stroke="#C4C4C4" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/></svg></span>',
      nextArrow:
        '<span class="slick-next"><svg width="8" height="14" viewBox="0 0 8 14" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M7 13L1 7L7 1" stroke="#C4C4C4" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/></svg></span>',
      responsive: [{ breakpoint: 640, settings: { slidesToShow: 3 } }],
    });

    $fort.on("beforeChange", function (event, slick, currentSlide, nextSlide) {
      $wing.slick("slickGoTo", nextSlide);
    });
    $wing.on("beforeChange", function (event, slick, currentSlide, nextSlide) {
      $fort.slick("slickGoTo", nextSlide);
    });
    $(document).click(function (e) {
      var $currItem = $(e.target).closest(".fort__slide");
      if ($currItem.length !== 0) {
        var currIndex = $currItem.closest(".slick-slide").attr("data-slick-index");

        $fort.slick("slickGoTo", currIndex);
      }
    });
    $(".carousel-services").slick({
      infinite: true,
      dots: true,
      arrows: false,
      slidesToShow: 1,
      slidesToScroll: 1,
      autoplay: true,
      autoplaySpeed: 5000,
      autoplayHoverPause: true,
    });
    $(".carousel-news").slick({
      infinite: true,
      arrows: false,
      slidesToShow: 1,
      slidesToScroll: 1,
      fade: true,
      swipeToSlide: true,
      autoplay: true,
      autoplaySpeed: 7000,
      autoplayHoverPause: true,
    });

    $(".carousel-news-anchor").on("click", function (e) {
      e.preventDefault();
      const $this = $(this);
      const index = $this.closest(".carousel-news-links").find(".carousel-news-anchor").index($this);
      $(".carousel-news").slick("slickGoTo", index);
    });

    $(".project-gallery").slick({
      infinite: false,
      dots: true,
      arrows: true,
      slidesToShow: 1,
      slidesToScroll: 1,
      autoplay: false,
      swipeToSlide: true,
      prevArrow:
        '<span><svg class="svg-inline--fa fa-angle-left fa-w-8 slick-prev fa-4x" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 512"><path fill="currentColor" d="M31.7 239l136-136c9.4-9.4 24.6-9.4 33.9 0l22.6 22.6c9.4 9.4 9.4 24.6 0 33.9L127.9 256l96.4 96.4c9.4 9.4 9.4 24.6 0 33.9L201.7 409c-9.4 9.4-24.6 9.4-33.9 0l-136-136c-9.5-9.4-9.5-24.6-.1-34z"></path></svg></span>',
      nextArrow:
        '<span><svg class="svg-inline--fa fa-angle-right fa-w-8 slick-next fa-4x" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 512"><path fill="currentColor" d="M224.3 273l-136 136c-9.4 9.4-24.6 9.4-33.9 0l-22.6-22.6c-9.4-9.4-9.4-24.6 0-33.9l96.4-96.4-96.4-96.4c-9.4-9.4-9.4-24.6 0-33.9L54.3 103c9.4-9.4 24.6-9.4 33.9 0l136 136c9.5 9.4 9.5 24.6.1 34z"></path></svg></span>'
    });
  });
})(jQuery);
