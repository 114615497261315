(function ($) {
  "use strict";
  $(function () {
    $(".res [type='file']").change(function () {
      let $nameBlock = $(this).closest(".res").find(".res__name");
      if ($(this).val() != "") {
        $nameBlock.text($(this)[0].files[0].name);
      } else $nameBlock.text("");
    });
  });
})(jQuery);
